import { useRoute, useRuntimeConfig } from '#imports'
import { computed } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { ROUTES as PRODUCT_LIST_ROUTES } from '~/scopes/product-list/route-names'

export function useMasterPPFeatureFlags() {
  const { market } = useMarketplace()
  const route = useRoute()
  const experiments = useExperiments()

  const {
    FF_ENABLE_COUNTRIES_MASTER_PP_ARTICLES,
    FF_ENABLE_COUNTRIES_MASTER_PP_URLS,
    FF_ENABLE_COUNTRIES_MASTER_PP_SCORECARD,
  } = useRuntimeConfig().public

  const isOnPlpPage = computed(
    () => route.name === PRODUCT_LIST_ROUTES.PRODUCT_LIST,
  )

  const isExperimentActive = computed(
    () => experiments['experiment.masterPP'] === 'withMasterPP',
  )

  const shouldShowArticles = computed(
    () =>
      ((FF_ENABLE_COUNTRIES_MASTER_PP_ARTICLES as string)?.includes(
        market.countryCode,
      ) ||
        isExperimentActive.value) &&
      isOnPlpPage.value,
  )

  const shouldUsePLPUrls = computed(
    () =>
      ((FF_ENABLE_COUNTRIES_MASTER_PP_URLS as string)?.includes(
        market.countryCode,
      ) &&
        isOnPlpPage.value) ||
      isExperimentActive.value,
  )

  const shouldAddCanonicalUrl = computed(
    () =>
      (FF_ENABLE_COUNTRIES_MASTER_PP_URLS as string)?.includes(
        market.countryCode,
      ) || isExperimentActive.value,
  )

  const shouldShowScoreCard = computed(
    () =>
      (FF_ENABLE_COUNTRIES_MASTER_PP_SCORECARD as string)?.includes(
        market.countryCode,
      ) || isExperimentActive.value,
  )

  return {
    shouldAddCanonicalUrl,
    shouldUsePLPUrls,
    shouldShowArticles,
    shouldShowScoreCard,
  }
}
