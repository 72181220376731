import { useRoute } from '#imports'
import { computed } from 'vue'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { ROUTES as PRODUCT_LIST_ROUTES } from '~/scopes/product-list/route-names'

import { PP_IDS, SLUGS } from '../constants/data.prod'

export function useMasterPP() {
  const route = useRoute()
  const marketCountryCode = useMarketplace().market.countryCode

  const PP_IDS_BY_PLP_ID = computed(() => PP_IDS[marketCountryCode] || {})
  const SLUGS_BY_PLP_ID = computed(() => SLUGS[marketCountryCode] || {})

  const PLP_ID_BY_PP_IDS = computed(() => {
    const result = new Map<string, string>([])

    Object.entries(PP_IDS_BY_PLP_ID.value).forEach(([plpId, productIds]) => {
      productIds.forEach((productId: string) => {
        result.set(productId, plpId)
      })
    })

    return result
  })

  function getProductId(plpId: string) {
    return PP_IDS_BY_PLP_ID.value[plpId]?.[0] || null
  }
  function getPlpId(productId: string) {
    return PLP_ID_BY_PP_IDS.value.get(productId) || null
  }

  function getPlpSlug(plpId: string) {
    return SLUGS_BY_PLP_ID.value[plpId] || 'undefined'
  }

  const isPlpUrl = computed(
    () => route.name === PRODUCT_LIST_ROUTES.PRODUCT_LIST,
  )

  const plpIdInUrl = computed(() => {
    if (isPlpUrl.value) {
      return route.params.uuid as string
    }

    return null
  })

  const masterProductId = computed(() => {
    if (!plpIdInUrl.value) {
      return null
    }

    return getProductId(plpIdInUrl.value) ?? null
  })

  return {
    getProductId,
    getPlpSlug,
    getPlpId,
    plpIdInUrl,
    isPlpUrl,
    masterProductId,
  }
}
